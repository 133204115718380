<template>
    <div class="container-fluid">
   <div class="row">
      <div class="col-sm-12">
         <div class="card">
            <div class="card-header d-flex justify-content-between">
               <div class="header-title">
                  <h4 class="card-title">Lista de artigos</h4>
               </div>
            </div>
            <div class="card-body">
               <div class="table-responsive">
                  <div class="row justify-content-between">
                     <div class="col-sm-6 col-md-6">
                        <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                        <br>
                     </div>
                     <div class="col-sm-6 col-md-6 py-2">
                        <div class="list-files d-flex">
                           <a class="btn bg-primary" @click="goToWildBackofficeAdd()">
                              Adicionar produto
                           </a>
                        </div>
                     </div>
                  </div>
                  <b-table
                     :items="articles" 
                     :fields="fields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                  <template #cell(finalPrice)="{item}">
                     {{ `${item.finalPrice}€` }}
                  </template>
                     <template #cell(actions)>
                        <div class="d-flex align-items-center list-user-action">
                           <a @click="goToWildBackofficeEdit()" class="btn btn-sm bg-primary mr-1">
                              <i class="ri-pencil-line mr-0"></i>
                           </a>
                        </div>                     
                     </template>
                  </b-table> 
               </div>
               <div class="row justify-content-between mt-3">
                  <div id="user-list-page-info" class="col-md-6">
                     <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                  </div>
                  <div class="col-md-6">
                     <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end mb-0">
                           <bPagination 
                              :total-rows="totalRows" 
                              v-model="currentPage" 
                              :per-page="perPage" 
                              @input ="updatePage(currentPage)"
                           >
                           </bPagination>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import articles from '../../../../../public/json/artigos.json';
export default {
   name:'UserList',
   data() {
      return {
         filter: null,
         fields: ['id', {key: 'title', label: 'Título'}, {key: 'finalPrice', label: 'Preço (desde)'}, {key: 'tourismType', label: 'Tipo de turismo'}, {actions: { label: 'Ações'}}],
         articles: [],
         perPage: 5,
         currentPage: 1,
         startIndex : 0,
         endIndex : 5,
         totalRows: 1,
      }
   },
   mounted () {
      this.articles = articles.data;
      this.totalRows = articles.data.length;
    },
   methods: {
      updatePage : function(activePage) {
         this.currentPage = activePage;
         this.startIndex = (this.currentPage * 5) - 5;
         this.endIndex = this.startIndex + 5;
      },
      pathJoin(parts)
      {
         return parts.map((part, i) => 
         {
            if (i === 0) {
               return part.toString().trim().replace(/[\/]*$/g,''); //eslint-disable-line
            } else{
               return part.toString().trim().replace(/(^[\/]*|[\/]*$)/g,'') //eslint-disable-line
            }
         }).filter(x=>x.length).join('/');
      },
      goToWildBackofficeAdd()
      {
         var fullUrl = encodeURI(this.pathJoin([process.env.VUE_APP_BACKOFFICE, '/experiences/add']));
         console.log(fullUrl)
         window.open(fullUrl, '_blank');
      },
      goToWildBackofficeEdit()
      {
         var fullUrl = encodeURI(this.pathJoin([process.env.VUE_APP_BACKOFFICE, '/experiences']));
         window.open(fullUrl, '_blank');
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
}
</script>